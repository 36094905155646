import { useNavigate } from 'react-router-dom';
import BackButton from '@/components/BackButton';
import { motion } from 'framer-motion';
import SpringBox from '@/components/AnimatedBoxes/SpringBox';
import { useFormik } from 'formik';
import { bvnVerificationSchema } from '@/components/AddBankAccount/validationSchema';
import Input from '@/components/Inputs/Input';
import { useGetCode, useVerifyBVN } from '@/hooks/useBankVerification';
import CheckFill from '@/components/Icons/CheckFill';
import Spinner from '@/components/Icons/Spinner';
import ClosedFill from '@/components/Icons/ClosedFill';
import SelectInput from '@/components/Inputs/SelectInput';
import { Button } from '@/components/Button';
import { useAppMutation } from '@/hooks/useAppQuery';
import { Toast } from '@/helpers/toastify.helpers';
import { useQueryClient } from '@tanstack/react-query';

type BVNVerificationValues = {
  bvn: string;
  verificationType: string;
  otp: string;
};

const BVNVerification = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: verifyCode, isLoading: isLoadingVerifyCode } = useAppMutation(
    {
      url: `v1/user/bvn`,
      method: 'PATCH',
    },
    {
      onSuccess: async () => {
        Toast({
          message: `BVN verification successful`,
          type: 'success',
        });
        await queryClient.invalidateQueries({
          queryKey: ['user'],
          refetchType: 'all',
        });
        navigate(-1);
      },
    }
  );

  const formik = useFormik<BVNVerificationValues>({
    initialValues: {
      bvn: '',
      verificationType: '',
      otp: '',
    },
    validationSchema: bvnVerificationSchema,
    enableReinitialize: true,

    onSubmit: ({ otp }) => {
      verifyCode({ code: otp });
    },
  });

  const isFieldValid = (field: keyof BVNVerificationValues) => {
    try {
      bvnVerificationSchema.validateSyncAt(field, formik.values);
      return true;
    } catch (error) {
      return false;
    }
  };

  const { BVNIsVerified, isLoadingVerifyBVN, verificationTypes } = useVerifyBVN(
    { isValid: isFieldValid('bvn'), formik }
  );

  const { getCode, isLoadingGetCode, isCodeSent, countDown, minutes, seconds } =
    useGetCode({ isValid: isFieldValid('verificationType'), formik });

  const renderBVNSideIcon = () => {
    if (BVNIsVerified) {
      return <CheckFill />;
    } else if (isLoadingVerifyBVN) {
      return <Spinner />;
    } else if (BVNIsVerified === false) {
      return <ClosedFill />;
    }
  };

  return (
    <section className="mx-4 my-20 md:mx-auto md:mt-[152px] md:w-96 lg:mt-24">
      <div className="mb-6 flex w-full items-center justify-center">
        <BackButton onClick={() => navigate(-1)} />
        <motion.h1
          className="flex-1 text-center text-2xl font-bold"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          BVN Verification
        </motion.h1>
      </div>

      <SpringBox>
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <Input
              maxLength={11}
              label="Bank Verification Number"
              placeholder="Enter your BVN"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="bvn"
              id="bvn"
              value={formik.values.bvn}
              errored={formik.touched.bvn && formik.errors.bvn}
              errorMessage={formik.errors.bvn}
              SideIcon={renderBVNSideIcon()}
            />
            <div>
              <SelectInput
                label="Type of Verification"
                options={verificationTypes}
                noOptionsMessage={() => 'Please verify your BVN first'}
                placeholder="Select"
                name="verificationType"
                onChange={(selected, { name }) => {
                  if (!selected || !name) return;
                  return formik.setFieldValue(name, selected.value);
                }}
                onBlur={formik.handleBlur}
                errored={
                  formik.touched.verificationType &&
                  formik.errors.verificationType
                }
                errorMessage={formik.errors.verificationType}
              />
              <small className="-mt-1 block text-xs text-shuttlegray">
                A code will be sent to the email or phone number attached to
                your BVN
              </small>
            </div>
            <div>
              <Input
                maxLength={5}
                label="Verification Code"
                placeholder="Enter OTP code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="otp"
                id="otp"
                value={formik.values.otp}
                errored={formik.touched.otp && formik.errors.otp}
                errorMessage={formik.errors.otp}
                SideIcon={
                  isFieldValid('verificationType') && (
                    <Button
                      variant="ghost"
                      className="p-0 text-jungleGreen hover:bg-transparent hover:underline disabled:bg-transparent disabled:hover:no-underline"
                      disabled={isCodeSent || !isFieldValid('bvn')}
                      loading={isLoadingGetCode}
                      loadingIndicator={<Spinner className="size-4" />}
                      onClick={() => {
                        getCode({ chosen: formik.values.verificationType });
                      }}
                    >
                      {isCodeSent ? 'OTP Sent' : 'Resend OTP'}
                    </Button>
                  )
                }
              />
              {isCodeSent && countDown > 0 && (
                <small className="-mt-1 block text-xs text-shuttlegray">
                  Request new code in{' '}
                  <span className="text-jungleGreen">
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </small>
              )}
            </div>
          </div>
          <Button
            type="submit"
            className="mt-10 w-full"
            loading={isLoadingVerifyCode}
            disabled={!formik.isValid}
          >
            Verify BVN
          </Button>
        </form>
      </SpringBox>
    </section>
  );
};

export default BVNVerification;
