import type { ComponentPropsWithoutRef } from 'react';
import twMerge from '../../utils/twMerge';

const Spinner = ({ className, ...props }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={twMerge(
        'inline-block size-6 animate-[spin_0.45s_linear_infinite] rounded-full border-2 border-uberblue border-b-transparent border-l-transparent',
        className
      )}
      {...props}
    />
  );
};

export default Spinner;
