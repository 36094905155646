import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import twMerge from '@/utils/twMerge';
import Label from '../Label';

export type ErrorProps =
  | { errored: boolean | string | undefined; errorMessage: string | undefined }
  | { errored?: never; errorMessage?: never };

type InputProps = {
  label?: string;
  containerClassName?: string;
  SideIcon?: ReactNode;
  actionButton?: ReactNode;
} & ErrorProps &
  ComponentPropsWithoutRef<'input'>;

const Input = ({
  label,
  errored,
  errorMessage,
  containerClassName,
  SideIcon,
  className,
  actionButton,
  ...rest
}: InputProps) => {
  const { name } = rest;

  return (
    <div className={twMerge('relative mb-2', containerClassName)}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div className={twMerge(actionButton && 'flex items-center gap-2')}>
        <div className="relative flex-1">
          <input
            className={twMerge(
              'w-full rounded-sm border-0.5 border-loblolly bg-transparent px-2 py-3 placeholder-loblolly focus:border-jungleGreen focus:outline-none disabled:border-loblolly disabled:bg-gallery disabled:text-loblolly',
              errored && 'mb-5 border-valencia focus:border-valencia',
              className
            )}
            {...rest}
          />
          {SideIcon && (
            <div className="absolute right-0 top-3 mr-2">{SideIcon}</div>
          )}
        </div>
        {actionButton && (
          <div className={twMerge(errored && '-mt-5')}>{actionButton}</div>
        )}
      </div>
      {errored && (
        <small className="absolute -bottom-[1px] left-0 truncate text-valencia">
          {errorMessage}
        </small>
      )}
    </div>
  );
};

export default Input;
