import type { ComponentPropsWithoutRef } from 'react';
import { theme } from 'twin.macro';

const ClosedFill = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.667 1.74512C15.4518 1.74512 18.1225 2.85136 20.0916 4.8205C22.0607 6.78963 23.167 9.46034 23.167 12.2451C23.167 15.0299 22.0607 17.7006 20.0916 19.6697C18.1225 21.6389 15.4518 22.7451 12.667 22.7451C9.88222 22.7451 7.2115 21.6389 5.24237 19.6697C3.27324 17.7006 2.16699 15.0299 2.16699 12.2451C2.16699 9.46034 3.27324 6.78963 5.24237 4.8205C7.2115 2.85136 9.88222 1.74512 12.667 1.74512ZM12.667 10.9716L10.228 8.53262C10.0591 8.36374 9.83007 8.26887 9.59124 8.26887C9.35241 8.26887 9.12337 8.36374 8.95449 8.53262C8.78562 8.70149 8.69074 8.93054 8.69074 9.16937C8.69074 9.4082 8.78562 9.63724 8.95449 9.80612L11.3935 12.2451L8.95449 14.6841C8.87087 14.7677 8.80454 14.867 8.75929 14.9763C8.71403 15.0855 8.69074 15.2026 8.69074 15.3209C8.69074 15.4391 8.71403 15.5562 8.75929 15.6655C8.80454 15.7747 8.87087 15.874 8.95449 15.9576C9.03811 16.0412 9.13738 16.1076 9.24664 16.1528C9.35589 16.1981 9.47299 16.2214 9.59124 16.2214C9.7095 16.2214 9.8266 16.1981 9.93585 16.1528C10.0451 16.1076 10.1444 16.0412 10.228 15.9576L12.667 13.5186L15.106 15.9576C15.1896 16.0412 15.2889 16.1076 15.3981 16.1528C15.5074 16.1981 15.6245 16.2214 15.7427 16.2214C15.861 16.2214 15.9781 16.1981 16.0873 16.1528C16.1966 16.1076 16.2959 16.0412 16.3795 15.9576C16.4631 15.874 16.5294 15.7747 16.5747 15.6655C16.62 15.5562 16.6432 15.4391 16.6432 15.3209C16.6432 15.2026 16.62 15.0855 16.5747 14.9763C16.5294 14.867 16.4631 14.7677 16.3795 14.6841L13.9405 12.2451L16.3795 9.80612C16.4631 9.7225 16.5294 9.62323 16.5747 9.51397C16.62 9.40472 16.6432 9.28762 16.6432 9.16937C16.6432 9.05111 16.62 8.93401 16.5747 8.82476C16.5294 8.71551 16.4631 8.61624 16.3795 8.53262C16.2959 8.449 16.1966 8.38267 16.0873 8.33741C15.9781 8.29216 15.861 8.26887 15.7427 8.26887C15.6245 8.26887 15.5074 8.29216 15.3981 8.33741C15.2889 8.38267 15.1896 8.449 15.106 8.53262L12.667 10.9716Z"
        fill={`${theme`colors.valencia.DEFAULT`}`}
      />
    </svg>
  );
};

export default ClosedFill;
