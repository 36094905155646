import type { ChangeEvent } from 'react';
import type {
  ClearIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
} from 'react-select';
import { components } from 'react-select';
import CheckBox from '../../CheckBox';
import CloseIcon from '../../Icons/CloseIcon';
import isEqual from 'lodash.isequal';
import ArrowDown from '@/components/Icons/ArrowDown';

export const DropdownIndicator = () => <ArrowDown width={24} height={24} />;

export const MultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>
) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

export const ClearIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon width={24} height={24} />
    </components.ClearIndicator>
  );
};

export const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  const { isMulti, isSelected, getValue, setValue, data } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    const updatedValue = isChecked
      ? [...getValue(), data]
      : getValue().filter((option) => !isEqual(option, data));

    setValue(
      updatedValue as OnChangeValue<Option, IsMulti>,
      isChecked ? 'select-option' : 'deselect-option',
      data
    );
  };

  return isMulti ? (
    <components.Option {...props}>
      <div onClick={(e) => e.stopPropagation()}>
        <CheckBox checked={isSelected} onChange={handleChange}>
          {children}
        </CheckBox>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>{children}</components.Option>
  );
};
