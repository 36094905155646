import type { ErrorProps } from '@/components/Inputs/Input';
import type { CustomStyles } from '@/components/Inputs/SelectInput';
import type { GroupBase, StylesConfig } from 'react-select';
import { theme } from 'twin.macro';

type StyleParams = Pick<ErrorProps, 'errored'> & CustomStyles;

export const customStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  errored,
  inputStyle,
  singleValueStyle,
  indicatorsContainerStyle,
  controlStyle,
}: StyleParams): StylesConfig<Option, IsMulti, Group> => ({
  valueContainer: (provided) => {
    const padding = '0.75rem 0.5rem';

    return { ...provided, padding };
  },
  indicatorSeparator: (provided) => {
    return { ...provided, display: 'none' };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      color: `${theme`colors.ebonyclay`}`,
      paddingRight: '0.5rem',
      ...indicatorsContainerStyle,
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      margin: '0px',
      color: `${theme`colors.loblolly`}`,
      whiteSpace: 'nowrap',
    };
  },
  option: (provided, state) => {
    const bgColor = state.isFocused
      ? `${theme`colors.jungleGreen.20`}`
      : 'transparent';
    return {
      ...provided,
      backgroundColor: bgColor,
      '&:active': {
        backgroundColor: `${theme`colors.jungleGreen.30`}`,
      },
      color: `${theme`colors.ebonyclay`}`,
      cursor: 'pointer',
      padding: '10px 16px',
      fontSize: '14px',
      lineHeight: '16px',
    };
  },
  control: (provided, state) => {
    const defaultBorderColor = state.isFocused
      ? `${theme`colors.jungleGreen.DEFAULT`}`
      : `${theme`colors.loblolly`}`;
    const borderColor = errored
      ? `${theme`colors.valencia.DEFAULT`}`
      : `${defaultBorderColor}`;
    return {
      ...provided,
      borderRadius: '0.125rem',
      border: `0.5px solid ${borderColor}`,
      backgroundColor: state.isDisabled
        ? `${theme`colors.gallery`}`
        : 'transparent',
      boxShadow: 'none',
      outline: 'none',
      '&:hover': {
        borderColor: 'none',
      },
      '&:focus': {
        borderColor: `${theme`colors.jungleGreen.DEFAULT`}`,
      },
      cursor: 'pointer',
      ...controlStyle,
    };
  },
  input: (provided) => {
    return {
      ...provided,
      padding: '0px',
      margin: '0px',
      ...inputStyle,
    };
  },
  singleValue: (provided, state) => {
    const color = state.isDisabled
      ? `${theme`colors.loblolly`}`
      : `${theme`colors.ebonyclay`}`;
    return {
      ...provided,
      color,
      margin: '0px',
      ...singleValueStyle,
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      margin: '4px 0px',
      borderRadius: '2px',
      boxShadow: `0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)`,
      animation: 'slideDown 0.2s ease-in-out',
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0,
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: `${theme`colors.uberblue`}`,
      color: 'white',
      borderRadius: '18px',
      padding: '4px 8px',
      gap: '4px',
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: 'white',
      padding: 0,
      paddingLeft: 0,
      fontSize: '12px',
      lineHeight: '16px',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      padding: 0,
      borderRadius: '9999px',
      '&:hover': {
        backgroundColor: '#6A6F76',
        color: 'white',
      },
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      color: `${theme`colors.uberblue`}`,
      '&:hover': {
        color: `${theme`colors.ebonyclay`}`,
      },
    };
  },
});
