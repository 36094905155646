import {
  open as openAction,
  close as closeAction,
} from '../components/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/redux/typedHooks';
import type User from '@/types/User';
import {
  userLoggedIn,
  userLoggedOut,
  userUpdated,
} from '../redux/features/auth/authSlice';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useActions = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isModalOpen } = useAppSelector((state) => state.modal);

  const open = (...param: unknown[]) => dispatch(openAction(...param));
  const close = (...param: unknown[]) => {
    const [cb, isAppMenu] = param;
    closeAction(cb, dispatch, !!isAppMenu);
  };

  const updateUser = (data: User) => dispatch(userUpdated(data));
  const loginUser = (param: { token: string }) => dispatch(userLoggedIn(param));
  const logout = () => {
    dispatch(userLoggedOut());
    if (isModalOpen) close(null);
    queryClient.clear();
    navigate('/login');
  };

  return {
    open,
    close,
    updateUser,
    loginUser,
    logout,
  };
};

export default useActions;
