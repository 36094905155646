import twMerge from '@/utils/twMerge';
import type { ComponentPropsWithoutRef } from 'react';

const Label = ({ className, ...props }: ComponentPropsWithoutRef<'label'>) => {
  return (
    <label
      className={twMerge('mb-2 block text-blueWood', className)}
      {...props}
    />
  );
};

export default Label;
