import { object, string } from 'yup';

export const addAccountSchema = object().shape({
  bankCode: string().required('Bank Name is required'),
  bankName: string().required(),
  accNo: string()
    .required('Account Number is required')
    .matches(/^[0-9]+$/, 'You must type a number')
    .length(10, 'Account Number must be 10 digits'),
  accName: string().required('Account Name is required'),
});

export const bvnVerificationSchema = object().shape({
  bvn: string()
    .required('BVN is required')
    .matches(/^[0-9]+$/, 'You must type a number')
    .length(11, 'BVN must be 11 digits'),
  verificationType: string().required('Type of Verification is required'),
  otp: string()
    .required('OTP is required')
    .matches(/^[0-9]+$/, 'You must type a number')
    .length(5, 'OTP must be 5 digits'),
});

export const addAccountUSDSchema = object().shape({
  destination: string().required('Destination is required'),
  bankName: string().required('Bank Name is required'),
  bankAddress: string().required('Bank Address is required'),
  bankSwiftCode: string(),
  bankSortCode: string().matches(/^[0-9]+$/, 'You must type a number'),
  routingNo: string().matches(/^[0-9]+$/, 'You must type a number'),
  accNo: string()
    .required('Account Number is required')
    .matches(/^[0-9]+$/, 'You must type a number'),
  accName: string().required('Account Name is required'),
});
