import twMerge from '@/utils/twMerge';
import ArrowLeft from './Icons/ArrowLeft';
import type { ComponentPropsWithoutRef } from 'react';

type BackButtonProps = {
  textClassName?: string;
} & ComponentPropsWithoutRef<'div'>;

const BackButton = ({
  className,
  textClassName,
  ...props
}: BackButtonProps) => {
  return (
    <div
      className={twMerge(
        'flex w-fit cursor-pointer items-center gap-1',
        className
      )}
      {...props}
    >
      <ArrowLeft />
      <span
        className={twMerge('text-uberblue hover:text-ebonyclay', textClassName)}
      >
        Back
      </span>
    </div>
  );
};

export default BackButton;
