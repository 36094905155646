import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import type { Ref } from 'react';

export type SpringBoxProps = {
  innerRef?: Ref<HTMLDivElement>;
} & HTMLMotionProps<'div'>;

const SpringBox = ({ children, innerRef, ...props }: SpringBoxProps) => {
  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{
        type: 'spring',
        mass: 1,
        stiffness: 177.8,
        damping: 20,
      }}
      ref={innerRef}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default SpringBox;
