import { useAppMutation, useAppQuery } from './useAppQuery';
import { Toast } from '../helpers/toastify.helpers';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@/redux/typedHooks';
import { useQueryClient } from '@tanstack/react-query';
import type FormikType from '@/types/FormikType';
import type { FormNGNValues } from '@/components/AddBankAccount/FormNGN';
import type Option from '@/types/Option';

type BVNValues = {
  bvn: string;
  verificationType: string;
  otp: string;
};

type CommonProps = {
  isValid: boolean;
  formik: FormikType<BVNValues>;
};

type GetAccountNameProps = {
  isValid: boolean;
  formik: FormikType<FormNGNValues>;
};

type VerifyBVNResponse = {
  result: string[];
};

export const useVerifyBVN = ({ isValid, formik }: CommonProps) => {
  const [BVNIsVerified, setBVNIsVerified] = useState<boolean | null>(null);
  const [verificationTypes, setVerificationTypes] = useState<Option[]>([]);

  const { mutate: verifyBVN, isLoading: isLoadingVerifyBVN } =
    useAppMutation<VerifyBVNResponse>(
      {
        url: `v1/user/bvn/check`,
      },
      {
        customErrorAction: () => {
          setBVNIsVerified(false);
        },
        onSuccess: (res) => {
          const { result } = res || {};
          setBVNIsVerified(true);
          setVerificationTypes(
            result.map((type) => ({ value: type, label: type }))
          );
        },
      }
    );

  useEffect(() => {
    if (isValid) {
      verifyBVN({ bvn: formik.values.bvn });
    }
  }, [formik.values.bvn, isValid, verifyBVN]);

  return {
    isLoadingVerifyBVN,
    BVNIsVerified,
    verificationTypes,
  };
};

export const useGetCode = ({ isValid, formik }: CommonProps) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCodeSent) {
        if (countDown > 1) {
          setCountDown((prev) => prev - 1);
        } else {
          setIsCodeSent(false);
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown, isCodeSent]);

  const { mutate: getCode, isLoading: isLoadingGetCode } = useAppMutation(
    {
      url: `v1/user/bvn`,
      method: 'POST',
    },
    {
      onSuccess: () => {
        Toast({
          message: `OTP code has been sent succesfully to ${formik.values.verificationType}`,
          type: 'success',
        });
        setIsCodeSent(true);
        setCountDown(120);
      },
    }
  );

  useEffect(() => {
    if (isValid) {
      getCode({ chosen: formik.values.verificationType });
    }
  }, [formik.values.verificationType, getCode, isValid]);

  return {
    getCode,
    isLoadingGetCode,
    isCodeSent,
    countDown,
    minutes,
    seconds,
  };
};

type AccountDetails = {
  account?: string;
  code?: string;
  allowedToFetch: boolean;
};

type GetAccountNameResponse = {
  data: { account: string; name: string };
};

export const useGetAccountName = ({ isValid, formik }: GetAccountNameProps) => {
  const [accountDetails, setAccountDetails] = useState<AccountDetails>({
    allowedToFetch: false,
  });

  const {
    data,
    isLoading: isLoadingFetchAccount,
    error,
    isFetching,
    isFetched,
  } = useAppQuery<GetAccountNameResponse>(
    ['account-name', accountDetails],
    {
      url: `v1/bank/resolve?account=${accountDetails.account}&code=${accountDetails.code}`,
    },
    {
      enabled: accountDetails.allowedToFetch,
    }
  );

  useEffect(() => {
    if (isValid) {
      setAccountDetails((prev) => ({
        ...prev,
        account: formik.values.accNo,
        code: formik.values.bankCode,
        allowedToFetch: true,
      }));
    } else {
      void formik.setFieldValue('accName', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bankCode, formik.values.accNo, isValid]);

  useEffect(() => {
    if (isFetched && !isFetching) {
      const name = data?.data.name;
      const message = error?.response?.data.message;

      if (!name) {
        Toast({
          message: 'Account not found, try again',
          type: 'error',
        });
        return;
      }

      if (message) {
        Toast({
          message,
          type: 'error',
        });
        return;
      }

      void formik.setFieldValue('accName', name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails, isFetched, isFetching, data, error]);

  return { isLoadingFetchAccount };
};

export const useAddBankAccount = () => {
  const { user } = useAppSelector((state) => state.auth);
  const queryClient = useQueryClient();

  const { mutate: addBankAccount, isLoading: isLoadingAddBankAccount } =
    useAppMutation(
      {
        url: `v1/bank`,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [`user-banks-${user.id}`],
            refetchType: 'all',
          });
          await queryClient.invalidateQueries({
            queryKey: [`user-banks-virtual-${user.id}`],
            refetchType: 'all',
          });
          await queryClient.invalidateQueries({
            queryKey: [`user`],
            refetchType: 'all',
          });
        },
      }
    );

  return {
    addBankAccount,
    isLoadingAddBankAccount,
  };
};
