import { customStyles } from '@/helpers/reactselect.helpers';
import twMerge from '@/utils/twMerge';
import type { CSSObjectWithLabel, GroupBase, Props } from 'react-select';
import Select from 'react-select';
import Label from '../../Label';
import type { ErrorProps } from '../Input';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  Option,
} from './components';
import type { ReactNode } from 'react';

export type CustomStyles = {
  inputStyle?: CSSObjectWithLabel;
  singleValueStyle?: CSSObjectWithLabel;
  indicatorsContainerStyle?: CSSObjectWithLabel;
  controlStyle?: CSSObjectWithLabel;
};

type SelectInputProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  label?: string;
  disabled?: boolean;
  actionButton?: ReactNode;
} & ErrorProps &
  CustomStyles &
  Props<Option, IsMulti, Group>;

const SelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  errored,
  errorMessage,
  disabled,
  inputStyle,
  singleValueStyle,
  indicatorsContainerStyle,
  controlStyle,
  className,
  actionButton,
  ...rest
}: SelectInputProps<Option, IsMulti, Group>) => {
  const { name } = rest;

  return (
    <div className="relative mb-2">
      {label && <Label htmlFor={name}>{label}</Label>}
      <div
        className={twMerge(
          disabled && 'cursor-not-allowed',
          actionButton && 'flex items-center gap-2'
        )}
      >
        <div className="flex-1">
          <Select
            inputId={name}
            styles={{
              ...customStyles<Option, IsMulti, Group>({
                errored,
                inputStyle,
                singleValueStyle,
                indicatorsContainerStyle,
                controlStyle,
              }),
            }}
            blurInputOnSelect={false}
            hideSelectedOptions={false}
            components={{
              DropdownIndicator,
              MultiValueRemove,
              ClearIndicator,
              Option,
            }}
            className={twMerge('focus:outline-none', className)}
            isDisabled={disabled}
            {...rest}
          />
        </div>
        {actionButton && actionButton}
      </div>
      {errored && (
        <small className="truncate text-valencia">{errorMessage}</small>
      )}
    </div>
  );
};

export default SelectInput;
