import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import twMerge from '../utils/twMerge';
import Check from './Icons/Check';

type CheckBoxProps = {
  checked: boolean;
  label?: ReactNode;
  containerClassName?: string;
  textSize?: 'small' | 'normal';
  noCenterAlign?: boolean;
} & ComponentPropsWithoutRef<'input'>;

const CheckBox = ({
  checked,
  className,
  label,
  containerClassName,
  children,
  textSize = 'small',
  noCenterAlign,
  ...props
}: CheckBoxProps) => {
  return (
    <label
      className={twMerge(
        'group flex cursor-pointer gap-2',
        !noCenterAlign && 'items-center',
        containerClassName
      )}
    >
      <div className="p-1">
        <label
          className={twMerge(
            'flex size-4 cursor-pointer items-center justify-center rounded-sm border border-loblolly group-hover:border-jungleGreen',
            checked && 'border-jungleGreen',
            className
          )}
        >
          <input
            type="checkbox"
            className="hidden"
            checked={checked}
            {...props}
          />
          {checked && <Check />}
        </label>
      </div>
      <div className={twMerge(textSize === 'small' && 'text-sm')}>
        {label || children}
      </div>
    </label>
  );
};

export default CheckBox;
